@import "./themes";

@mixin nb-overrides() {

  nb-icon,
  nb-icon.fa,
  nb-icon.fa-regular,
  nb-icon.fa-solid {
    display: flex;
    align-items: center;
  }

  nb-select.size-medium button {
    padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;

    nb-icon {
      right: 0.41rem !important;
    }
  }

  nb-action nb-icon {
    color: unset !important;
  }

  nb-action {
    height: unset !important;
  }

  nb-stepper .step-content {
    text-align: center;

    button {
      cursor: pointer;
      margin: 0.5rem;
    }
  }

  // nb-card
  nb-card {
    margin-bottom: 0.5rem !important;
    transform: translate3d(0, 0, 0);
    border: 1px solid nb-theme(border-basic-color-3);
  }

  nb-card-header,
  nb-card-footer {
    padding: 0.25rem 0.5rem !important;
  }

  nb-card-body {
    display: block;
    padding: 0.25rem 1rem !important;
  }

  // nb-card

  // nb-accordion
  nb-accordion-item-header {
    padding: 0.25rem 0.5rem !important;
  }

  nb-accordion-item-body .item-body {
    padding: 0.25rem 1rem !important;
  }

  // nb-accordion

  // nb-tab
  nb-tabset {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  nb-tabset ul.tabset li.tab {
    border: 1px solid nb-theme(border-basic-color-3);
    background-color: nb-theme(background-basic-color-3);
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;

    a {
      padding: 8px 6px 8px 6px !important;
    }

    a::before {
      height: 2px !important;
      bottom: 0px !important;
    }
  }

  nb-tabset ul.tabset li.tab.active {
    background-color: nb-theme(background-basic-color-1);
  }

  nb-tabset .tab-link {
    text-transform: none !important;
  }

  nb-tabset nb-tab {
    border: 1px solid nb-theme(border-basic-color-3);
    border-radius: 0.17rem;
    padding: 0.5rem !important;
  }

  nb-tab {
    overflow-x: scroll;
  }

  // nb-tab

  // nb-stepper
  nb-stepper .step-content button {
    margin: unset;
  }

  nb-stepper .step-content .prev-button,
  nb-stepper .step-content .next-button {
    margin: 0.5rem;
  }

  // nb-stepper

  // nb-actions

  // nb-actions

  // nb-toggle
  $nb-toggle_height: 1.2rem;
  $nb-toggle_width: 2rem;
  $nb-toggle_toggle-switcher_margin: 0.3px;
  $nb-toggle_toggle-switcher_size: 1.1rem;

  nb-toggle {
    .toggle {
      height: $nb-toggle_height !important;
      width: $nb-toggle_width !important;
    }

    .toggle-switcher {
      margin: $nb-toggle_toggle-switcher_margin !important;
      height: $nb-toggle_toggle-switcher_size !important;
      width: $nb-toggle_toggle-switcher_size !important;
    }

    .toggle.checked .toggle-switcher {
      left: calc(100% - ($nb-toggle_toggle-switcher_size + (2* $nb-toggle_toggle-switcher_margin))) !important;
    }
  }
}

// nb-toggle


// nb-user

nb-user .user-container .user-picture.initials {
  pointer-events: none;
  background-color: nb-theme(input-basic-background-color);
}

// nb-user