@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");

// themes - our custom or/and out of the box themes
@import "themes";

// framework component themes (styles tied to theme variables)
@import "@nebular/theme/styles/globals";

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

// loading progress bar theme
@import "./pace.theme";
// loading ic-highlight
@import "./ic-highlight";
// loading ic-overlays
@import "./ic-overlays";
// loading ic-buttons
@import "./ic-buttons";
// loading inputs theme
@import "./inputs.theme";
// loading realtime-editible-input theme
@import "./realtime-editible-input.theme";
// loading ng-select theme
@import "./ng-select.theme";
// loading cdk-drag theme
@import "./cdk-drag.theme";
// tables
@import "./tables.theme";
// loading animations
@import "./animations";
// loading magic-text-editor
@import "../../components/@magic-text-editor/magic-text-editor.component.scss";

@import "./layout";
@import "./overrides";

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();

  @include ic-layout();
  // loading progress bar
  @include ic-pace-theme();
  // loading ic-highlight
  @include ic-highlight();
  // loading ic-overlays
  @include ic-overlays();
  // loading ic-buttons
  @include ic-buttons();
  // loading inputs theme
  @include inputs();
  // loading realtime-editible-input theme
  @include realtime-editible-input();
  // loading ng-select theme
  @include ng-select();
  // loading cdk-drag theme
  @include cdk-drag();
  // tables
  @include tables();
  // loading animations
  @include animations();
  // loading magic-text-editor
  @include magic-text-editor();

  @include nb-overrides();

  // Customizações na barra de rolagem
  // /* width */
  ::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    // box-shadow   : inset 0 0 5px grey !important;
    background: nb-theme(background-basic-color-1) !important;
    // border-radius: 10px !important;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: grey !important;
    // border-radius: 5px !important;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #000 !important;
  }

  // Customizações na barra de rolagem

  .padding {
    padding: 10px !important;
  }

  [padding] {
    padding: 10px !important;
  }


  // mat-dialog global
  .mdc-dialog,
  .mdc-dialog__container,
  .mat-mdc-dialog-container,
  .mat-dialog-container,
  .mat-mdc-dialog-surface {
    display: block !important;
    background-color: transparent !important;
    padding: unset !important;
    overflow: unset !important;
  }

  .mat-mdc-dialog-surface,
  .mdc-dialog__surface {
    box-shadow: nb-theme(shadow) !important;
  }

  // mat-dialog global

  hr {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .container {
    padding-right: 10px !important;
    padding-left: 10px !important;
    margin: unset !important;
    max-width: unset !important;
  }

  p.tiny-text,
  p.tiny-error {
    font-size: 0.8rem;
    font-style: italic;
    line-height: normal;
    margin: unset;
  }

  p.tiny-error {
    color: nb-theme(color-danger-400);
    border-color: nb-theme(color-danger-600);
  }

  .confirmation-input {
    font-family: "Consola-Bold";
  }

  .confirmation-code {
    font-family: "Consola-Bold";
    font-weight: bold;
    font-size: 1.2rem;
  }

  div[innerHtmlArea] {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      font-family: "Roboto" !important;
    }

    color: unset;
    border-width: 2px;
    border-color: nb-theme(border-basic-color-3) !important;
    border-style: solid !important;
    padding: 10px !important;
  }

  ng2-smart-table table tr th,
  ng2-smart-table table tr td {
    padding: 5px !important;
  }

  button {
    border: unset;
    background-color: transparent;
  }

  button.a-style {
    background-color: transparent;
    text-decoration: underline;
    font-weight: 600;
  }

  #confetti {
    pointer-events: none;
  }
}


;