@mixin cdk-drag() {
  .cdk-drag:not(.cdk-drag-preview) {
    cursor: grab;
  }

  .cdk-drag-preview {
    cursor: grabbing !important;
    z-index: 99999 !important;
    background-color: nb-theme(background-basic-color-1);
    box-shadow: nb-theme(shadow);
  }

  // .cdk-drag-placeholder {
  //   opacity: 0;
  // }

  .cdk-drag-animating {
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 0);
  }

  .cdk-drop-list-dragging .cdk-drag {
    transition: transform 100ms cubic-bezier(0, 0, 0.2, 0);

  }

}