@import "../../@theme/styles/themes";

@mixin magic-text-editor() {

  .magic-text-editor {
    position: relative;

    .magic-text-loader {
      position: absolute;

      inset: 0;
      z-index: 9999;

      display: flex;
      justify-content: center;
      align-items: center;
      visibility: visible;
      overflow: hidden;

      background-color: nb-theme(background-basic-color-1);
      border-radius: inherit;

      opacity: 0;
      visibility: hidden;
      transition: opacity 100ms ease-in-out, visibility 100ms ease-in-out;

      &.show {
        opacity: 0.6;
        visibility: visible;
      }

      &:before {
        content: '';
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 3px solid nb-theme(spinner-basic-background-color);
        /* Cor do spinner */
        border-color: nb-theme(spinner-primary-circle-filled-color) transparent nb-theme(spinner-primary-circle-filled-color) transparent;
        animation: spin 1.2s linear infinite;
      }
    }
  }

  .ck-content .mention {
    background: nb-theme(color-info-transparent-focus) !important;
    color: nb-theme(text-primary-focus-color) !important;
    border-radius: 4px !important;
    text-decoration: underline !important;
  }

  .ck {
    .ck-list__item .user-feed-item {
      display: flex;
      align-items: center;

      .user-feed-item-photo {
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: nb-theme(text-basic-color);
        background-color: nb-theme(background-basic-color-1);
        font-size: 12px;
        font-weight: bold;
        margin-right: 10px;
      }

      .user-feed-item-photo img.user-photo {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }

      .user-feed-item-name {
        color: nb-theme(text-basic-color);
        margin-right: 10px;
        font-weight: bold;
      }

      .user-feed-item-id {
        background: nb-theme(color-info-transparent-focus);
        color: nb-theme(text-primary-focus-color);
        border-radius: 4px;
        text-decoration: underline;
      }
    }
  }
}



// Manual step to change file "ckeditor.js"
// chage: ":root{--ck-z-default:1;--ck-z-modal:calc(var(--ck-z-default) + 999)}"
// to: ":root{--ck-z-default:100;--ck-z-modal:calc(var(--ck-z-default) + 1040)}"

@include nb-install-component {
  ::ng-deep {
    $--ck-base-spacing: 2em;
    $--ck-color-white: #fff;
    $--ck-color-green: nb-theme(color-success-default);
    $--ck-color-blue: nb-theme(color-primary-default);
    $--ck-container-width: 1285px;
    $--ck-sidebar-width: 350px;
    $--ck-editor-min-height: 200px;

    .magic-text-editor {
      display: block;
      min-height: 30px;

      padding: 0.2rem 0.3rem !important;
      overflow: hidden;

      position: relative;

      .loader {
        opacity: 1;
        position: absolute;
        border-radius: inherit;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        overflow: hidden;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
        visibility: visible;
      }
    }

    main .message h2 {
      font-size: 1.2em;
    }

    ::ng-deep .ck.ck-editor__editable.ck-read-only {
      background: #fafafa;
      color: #888;
    }

    /* --------- EDITOR STYLES  ---------------------------------------------------------------------------------------- */

    /* Geral */
    main {

      .editor__editable,
      /* Classic build. */
      .ck-editor[role='application'] .ck.ck-content,
      /* Decoupled document build. */
      .ck.editor__editable[role='textbox'],
      .ck.ck-editor__editable[role='textbox'],
      /* Inline & Balloon build. */
      .ck.editor[role="textbox"] {
        display: block;
        width: 100%;
        background-color: nb-theme(background-basic-color-1);
        font-size: 0.8em;
        line-height: 1.2em;
        min-height: $--ck-editor-min-height;
        padding: 0.8em 1em;
      }

      .ck-editor[role="application"] {
        overflow: auto;
      }

      .ck.ck-toolbar .ck.ck-toolbar__separator {
        background-color: nb-theme(border-basic-color-3);
      }

      .ck.ck-toolbar {
        color: nb-theme(text-basic-color);
        border: 1px solid nb-theme(border-basic-color-3);
        background-color: nb-theme(background-basic-color-1);
        z-index: calc(var(--ck-z-default) + 1);
        position: relative;
      }

      .ck.ck-button,
      a.ck.ck-button {
        position: relative;
        display: inline-flex;
        font-size: 12px;
        color: nb-theme(text-basic-color) !important;
        background-color: nb-theme(background-basic-color-1);
        border-color: nb-theme(border-basic-color-3);
        text-align: center;
        transition: box-shadow .2s ease-in-out, border .2s ease-in-out;
        vertical-align: middle;
        white-space: nowrap;
      }

      .ck.ck-button.ck-disabled,
      a.ck.ck-button.ck-disabled {
        color: nb-theme(text-basic-color);
        background-color: nb-theme(background-basic-color-4);
        opacity: var(--ck-disabled-opacity);
      }

      .ck.ck-button:not(.ck-disabled):hover,
      a.ck.ck-button:not(.ck-disabled):hover {
        color: nb-theme(text-basic-color);
        background-color: nb-theme(background-basic-color-4);
      }

      .ck.ck-button.ck-on,
      a.ck.ck-button.ck-on {
        color: nb-theme(text-basic-color);
        background-color: nb-theme(background-basic-color-4);
        opacity: 0.8;
      }

      // tooltip
      .ck.ck-tooltip.ck-tooltip_s {
        bottom: calc(var(--ck-tooltip-arrow-size)* -1);
        transform: translateY(100%);
      }

      .ck.ck-tooltip.ck-tooltip_s .ck-tooltip__text::after {
        top: calc(var(--ck-tooltip-arrow-size)* -1 + 1px);
        transform: translateX(-50%);
        border-left-color: transparent;
        border-bottom-color: var(--ck-color-tooltip-background);
        border-right-color: transparent;
        border-top-color: transparent;
        border-left-width: var(--ck-tooltip-arrow-size);
        border-bottom-width: var(--ck-tooltip-arrow-size);
        border-right-width: var(--ck-tooltip-arrow-size);
        border-top-width: 0;
      }

      .ck.ck-tooltip {
        pointer-events: none;
        position: absolute;
        z-index: calc(var(--ck-z-default) + 1);
      }

      .ck.ck-button:focus .ck-tooltip {
        display: block;
        visibility: visible;
        opacity: 1;
      }

      .ck.ck-editor__editable {
        background-color: nb-theme(background-basic-color-1);
        width: 100%;
      }

      .ck-editor__editable:not(.ck-focused) {
        // border: 1px solid nb-theme(color-primary-focus);
        border-color: transparent !important;
      }

      /* Because of sidebar `position: relative`, Edge is overriding the outline of a focused editor. */
      .ck.ck-editor__editable {
        position: relative;
        z-index: calc(var(--ck-z-default) - 1);
      }

      .editor-container {
        display: block;
        width: 100%;
      }

      .document-editor__toolbar {
        width: 100%;
      }

      .row-editor {
        display: flex;
        max-height: 600px;
        position: relative;
        justify-content: center;
        overflow-y: auto;
        background-color: nb-theme(background-basic-color-3);
        border: 1px solid nb-theme(border-basic-color-3);
      }

      .sidebar {
        background: transparent;
        border: 0;
        box-shadow: none;
      }

      /* Do not inherit styles related to the editable editor content. See line 25.*/
      .sidebar .ck-content[role="textbox"],
      .ck.ck-annotation-wrapper .ck-content[role="textbox"] {
        min-height: unset;
        width: unset;
        padding: 0;
        background: transparent;
      }

      .sidebar.narrow {
        min-width: 60px;
        flex-grow: 0;
      }

      .sidebar.hidden {
        display: none !important;
      }

      #sidebar-display-toggle {
        position: absolute;
        z-index: 1;
        width: 30px;
        height: 30px;
        text-align: center;
        left: 15px;
        top: 30px;
        border: 0;
        padding: 0;
        color: nb-theme(text-hint-color);
        transition: 250ms ease color;
        background-color: transparent;
      }

      #sidebar-display-toggle:hover {
        color: nb-theme(text-hint-color);
        cursor: pointer;
      }

      #sidebar-display-toggle:focus,
      #sidebar-display-toggle:active {
        outline: none;
        border: 1px solid #a9d29d;
      }

      #sidebar-display-toggle svg {
        fill: currentColor;
      }

      /* --------- COMMENTS & TRACK CHANGES FEATURE ---------------------------------------------------------------------- */
      .sidebar {
        padding: 0 15px;
        position: relative;
        min-width: $--ck-sidebar-width;
        max-width: $--ck-sidebar-width;
        font-size: 20px;
        border: 1px solid nb-theme(border-basic-color-3);
        background-color: nb-theme(background-basic-color-3);
        border-left: 0;
        overflow: hidden;
        min-height: 100%;
        flex-grow: 1;
      }
    }
  }

  /* Classic build. */
  main.classic {
    .editor-container {
      display: block;
      width: 100%;
    }

    .row-editor .editor {
      width: 100%;
      height: 100%;
      background-color: nb-theme(background-basic-color-1);
    }

    .ck-editor[role="application"] .ck.ck-content {
      min-height: 600px !important;
    }
  }

  /* Classic build. */


  /* decoupledDocument. */
  main.decoupledDocument {
    .editor-container {
      display: contents;
      flex-direction: row;
      flex-wrap: nowrap;
      position: relative;
      width: 100%;
      justify-content: center;
      padding: 0;
    }

    .row-editor .ck.ck-content {
      min-height: 26.25cm;
      width: 18.5cm;
      height: 100%;
      margin: 2.5rem;
      box-shadow: nb-theme(shadow);
    }
  }

  /* decoupledDocument. */

}