@mixin ic-overlays() {

  .cdk-overlay-backdrop {
    background-color: nb-theme(overlay-backdrop-background-color);
    opacity: 0;
    transition: opacity 200ms ease-in-out;

    &.cdk-overlay-backdrop-showing {
      opacity: 0.6 !important;
    }
  }

  .ic-overlay {

    position: relative;

    .ic-overlay-loader {
      position: absolute;

      inset: 0;
      z-index: 9999;

      display: flex;
      justify-content: center;
      align-items: center;
      visibility: visible;
      overflow: hidden;

      background-color: nb-theme(background-basic-color-1);
      border-radius: inherit;

      opacity: 0;
      visibility: hidden;
      transition: opacity 100ms ease-in-out, visibility 100ms ease-in-out;

      &.show {
        opacity: 0.6;
        visibility: visible;
      }

      &:before {
        content: '';
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 3px solid nb-theme(spinner-basic-background-color);
        /* Cor do spinner */
        border-color: nb-theme(spinner-primary-circle-filled-color) transparent nb-theme(spinner-primary-circle-filled-color) transparent;
        animation: spin 1.2s linear infinite;
      }
    }

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    background-color: nb-theme(background-basic-color-1);
    border-radius: 3px;
    box-shadow: nb-theme(shadow);
    border: 1px solid nb-theme(border-basic-color-3);
    padding: 6px 10px 6px 10px;

    button {
      color: nb-theme(input-basic-text-color);
      background-color: transparent;

      padding: 5px 10px;
      border-radius: 8px;
      border-width: 1px;
      border-color: transparent;

      nb-icon,
      span {
        font-size: 0.8rem !important;
      }
    }

    button:hover,
    button.active {
      background-color: nb-theme(input-basic-background-color);
      border-color: nb-theme(color-primary-300);
    }

    button:disabled {
      opacity: 0.7 !important;
      cursor: not-allowed !important;
    }

    input,
    select {
      font-size: 0.8rem !important;
    }

    .ic-overlay-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      height: 30px;

      border-bottom: 1px solid nb-theme(border-basic-color-3);

      .ic-overlay-header-title {
        display: block;
        width: 100%;

        z-index: 1;

        font-size: 0.8rem;
        text-align: center;

        nb-icon {
          font-size: 0.8rem;
          display: inline-block;
        }
      }

      .ic-overlay-close-button {
        display: block;
        position: absolute;
        z-index: 2;
        right: 0;
        background-color: transparent !important;
      }
    }

    .ic-overlay-body {
      margin-top: 5px;
      margin-bottom: 5px;

      .input-row {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
      }
    }

    .ic-overlay-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      border-top: 1px solid nb-theme(border-basic-color-3);
    }
  }
}