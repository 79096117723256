@mixin ic-layout() {
  @include media-breakpoint-down(is) {
    .row {
      margin-left: -10px;
      margin-right: -10px;
    }
  }

  nb-layout-column {
    padding: nb-theme(layout-small-padding) !important;
  }
}