@mixin inputs() {

  label {
    margin-bottom: 0.2rem !important;
    margin-top: 0.2rem !important;
  }

  .input-group,
  .ic-input-group {
    margin-bottom: 1rem;
  }

  input[type="checkbox"] {
    margin-right: 5px;
  }

  input[type="color"] {
    height: 20px;
    width: 40px;
    padding-inline-end: 20px;
    margin-right: 10px;
  }

  h6.ic-form-control {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-height: 25px;
    padding: 0.2rem 0.3rem !important;
  }

  h6.ic-form-control.active {
    cursor: pointer !important;
  }

  .ic-form-control.small:not(.big) {
    // big
    font-size: 0.6rem;
  }

  .ic-form-control:not(.small):not(.big) {
    // normal
    font-size: 0.8rem;
  }

  .ic-form-control.big:not(.small) {
    // small
    font-size: 1rem;
  }

  .ic-form-control,
  input.ic-form-control,
  select.ic-form-control,
  textarea.ic-form-control {
    display: block;
    width: 100%;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    appearance: auto !important;
    padding-right: 0.15rem !important;
    padding-left: 0.15rem !important;
    padding-top: 0.1rem !important;
    padding-bottom: 0.1rem !important;
    line-height: 1.8 !important;
    border: 2px solid nb-theme(border-basic-color-3) !important;
    border-radius: 0.25rem;
    color: nb-theme(input-basic-text-color) !important;

    background-clip: padding-box;
    background-color: nb-theme(input-basic-background-color) !important;
  }

  .ic-form-control:not(textarea),
  input.ic-form-control,
  select.ic-form-control {
    height: 30px !important;
  }

  .ic-form-control[type="color"] {
    height: 30px !important;
    width: 30px !important;
  }

  input.ic-form-control:focus,
  select.ic-form-control:focus,
  textarea.ic-form-control:focus {
    color: nb-theme(input-basic-text-color) !important;
    background-color: nb-theme(input-basic-background-color) !important;
    border-color: nb-theme(color-primary-300) !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem nb-theme(color-primary-transparent-focus) !important;
  }

  .ic-form-control:disabled,
  .ic-form-control[readonly],
  h6.ic-form-control {
    background-color: nb-theme(background-basic-color-4) !important;
    opacity: 0.7 !important;
    cursor: not-allowed !important;
  }

  input.ng-touched.ng-invalid {
    color: nb-theme(color-danger-600) !important;
    border-color: nb-theme(color-danger-600) !important;
    border-width: 2px;
  }


}