@mixin ng-select() {

  input {
    height: unset;
  }

  // ADD this to styles
  // // Ng select
  // body {
  //   position: relative;
  // }
  // // Ng select
  // ADD this to styles

  // ng-select
  .ng-select {
    width: 100%;
  }

  .ng-select.ng-select-opened>.ng-select-container {
    background: nb-theme(input-basic-background-color);
    border-color: nb-theme(border-basic-color-3);
  }

  .ng-select.ng-select-opened>.ng-select-container:hover {
    box-shadow: none;
  }

  .ng-select.ng-select-opened>.ng-select-container .ng-arrow {
    top: -2px;
    border-color: transparent transparent nb-theme(input-basic-placeholder-text-color);
    border-width: 0 5px 5px;
  }

  .ng-select.ng-select-opened>.ng-select-container .ng-arrow:hover {
    border-color: transparent transparent nb-theme(input-basic-text-color);
  }

  .ng-select.ng-select-opened.ng-select-top>.ng-select-container {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .ng-select.ng-select-opened.ng-select-right>.ng-select-container {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .ng-select.ng-select-opened.ng-select-bottom>.ng-select-container {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .ng-select.ng-select-opened.ng-select-left>.ng-select-container {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    border-color: nb-theme(border-basic-color-3);
    box-shadow: 0 0 0 0.2rem nb-theme(color-primary-transparent-focus);
  }

  .ng-select.ng-select-disabled>.ng-select-container {
    // background-color: nb-theme(input-basic-disabled-background-color);
    background-color: nb-theme(background-basic-color-4);
    opacity: 0.8;
  }

  .ng-select .ng-has-value .ng-placeholder {
    display: none;
  }

  //     ng-select-container
  .ng-select .ng-select-container {
    line-height: 1.5;
    font-size: 0.8rem;
    color: nb-theme(input-basic-text-color);
    background-color: nb-theme(input-basic-background-color);
    border-radius: 0.25rem;
    border: 1px solid nb-theme(border-basic-color-3);
    min-height: 30px;
    align-items: center;
  }

  // .ng-select .ng-select-container:hover {
  // box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  // }
  .ng-select[required].ng-invalid.ng-touched .ng-select-container {
    color: nb-theme(color-danger-600);
    border-color: nb-theme(color-danger-600);
    border-width: 2px;
  }

  .ng-select[disabled] .ng-select-container {
    // background-color: nb-theme(input-basic-disabled-background-color);
    background-color: nb-theme(background-basic-color-4);
    opacity: 0.8;
  }

  //     ng-select-container

  .ng-select .ng-select-container .ng-value-container {
    align-items: center;
    padding-left: 0.3rem;
  }

  [dir="rtl"] .ng-select .ng-select-container .ng-value-container {
    padding-right: 0.3rem;
    padding-left: 0;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }

  .ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: nb-theme(input-basic-placeholder-text-color);
  }

  .ng-select.ng-select-single .ng-select-container {
    height: 30px;
  }

  // .ng-select .ng-select-container .ng-value-container .ng-input > input {
  //   padding-right: 0.3rem;
  //   padding-left: 0.3rem;
  //   padding-top: 0.2rem;
  //   padding-bottom: 0.2rem;
  // }
  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    // top: 5px;
    left: 0;
    // padding-left: 0.3rem;
    // padding-right: 50px;
    // padding-top: 0.2rem;
    // padding-bottom: 0.2rem;
  }

  [dir="rtl"] .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    padding-right: 0.3rem;
    padding-left: 50px;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }

  .ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value {
    // background-color: nb-theme(input-basic-disabled-background-color);
    background-color: nb-theme(background-basic-color-4);
    opacity: 0.8;
    border: 1px solid nb-theme(color-danger-200);
  }

  .ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value .ng-value-label {
    padding: 0 3px;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    padding-top: 2px;
    padding-left: 3px;
  }

  [dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    padding-right: 3px;
    padding-left: 0;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    font-size: 0.8rem;
    margin-bottom: 2px;

    color: nb-theme(input-basic-text-color);
    border: 2px solid nb-theme(color-primary-hover);
    // background-color: nb-theme(color-primary-hover);

    border-radius: 2px;
    margin-right: 5px;
  }

  [dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    margin-right: 0;
    margin-left: 5px;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
    // background-color: nb-theme(input-basic-disabled-background-color);
    background-color: nb-theme(background-basic-color-4);
    opacity: 0.8;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
    padding-left: 2px;
  }

  [dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
    padding-left: 0;
    padding-right: 2px;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
    display: inline-block;
    padding: 1px 2px;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
    display: inline-block;
    padding: 1px 2px;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
    border-radius: 2px;
    background-color: nb-theme(color-danger-400);
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    border-right: 1px solid nb-theme(color-primary-300);
  }

  [dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    border-left: 1px solid nb-theme(color-primary-300);
    border-right: none;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
    border-left: 1px solid nb-theme(color-primary-300);
  }

  [dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
    border-left: 0;
    border-right: 1px solid nb-theme(color-primary-300);
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
    padding: 0 0 3px 3px;
  }

  [dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
    padding: 0 3px 3px 0;
  }

  .ng-select .ng-select-container .ng-value-container .ng-input>input,
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input>input {
    background: transparent !important;
    color: nb-theme(input-basic-text-color);
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    // top: 5px;
    padding-bottom: 5px;
    // padding-left: 3px;
  }

  [dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    padding-right: 3px;
    padding-left: 0;
  }

  .ng-select .ng-clear-wrapper {
    color: nb-theme(input-basic-placeholder-text-color);
  }

  .ng-select .ng-clear-wrapper:hover .ng-clear {
    color: nb-theme(color-danger-400);
  }

  .ng-select .ng-spinner-zone {
    padding: 5px 5px 0 0;
  }

  [dir="rtl"] .ng-select .ng-spinner-zone {
    padding: 5px 0 0 5px;
  }

  .ng-select .ng-arrow-wrapper {
    width: 25px;
    padding-right: 5px;
  }

  [dir="rtl"] .ng-select .ng-arrow-wrapper {
    padding-left: 5px;
    padding-right: 0;
  }

  .ng-select .ng-arrow-wrapper:hover .ng-arrow {
    border-top-color: nb-theme(color-primary-hover);
  }

  .ng-select .ng-arrow-wrapper .ng-arrow {
    border-color: nb-theme(input-basic-placeholder-text-color) transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px;
  }

  // ng-select

  // ng-dropdown-panel
  .ng-dropdown-panel {
    background-color: nb-theme(input-basic-background-color);
    border: 1px solid nb-theme(border-basic-color-3);
    border-radius: 0.25rem;
    left: 0;
  }

  .ng-dropdown-panel.ng-select-top {
    bottom: 100%;
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    border-bottom-color: nb-theme(border-basic-color-3);
    margin-bottom: -1px;
  }

  .ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child {
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
  }

  .ng-dropdown-panel.ng-select-right {
    left: 100%;
    top: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-bottom-color: nb-theme(border-basic-color-3);
    margin-bottom: -1px;
  }

  .ng-dropdown-panel.ng-select-right .ng-dropdown-panel-items .ng-option:first-child {
    border-top-right-radius: 0.25rem;
  }

  .ng-dropdown-panel.ng-select-bottom {
    top: 100%;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-color: nb-theme(border-basic-color-3);
    margin-top: -1px;
  }

  .ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .ng-dropdown-panel.ng-select-left {
    left: -100%;
    top: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-bottom-color: nb-theme(border-basic-color-3);
    margin-bottom: -1px;
  }

  .ng-dropdown-panel.ng-select-left .ng-dropdown-panel-items .ng-option:first-child {
    border-top-left-radius: 0.25rem;
  }

  .ng-dropdown-panel .ng-dropdown-header {
    border-bottom: 1px solid nb-theme(border-basic-color-3);
    padding: 5px 7px;
  }

  .ng-dropdown-panel .ng-dropdown-footer {
    border-top: 1px solid nb-theme(border-basic-color-3);
    padding: 5px 7px;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
    user-select: none;
    padding: 2px 4px;
    font-weight: normal;
    color: nb-theme(input-basic-text-color);
    cursor: pointer;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
    cursor: default;
    // background-color: nb-theme(input-basic-disabled-background-color);
    background-color: nb-theme(background-basic-color-4);
    opacity: 0.8;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
    color: nb-theme(input-basic-text-color);
    border: 2px solid nb-theme(color-primary-hover);
    // background-color: nb-theme(color-primary-hover);
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
    color: nb-theme(input-basic-text-color);
    border: 2px solid nb-theme(color-primary-hover);
    // background-color: nb-theme(color-primary-hover);
    font-weight: bold;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    color: nb-theme(input-basic-text-color);
    border: 2px solid nb-theme(input-basic-background-color);
    font-weight: normal;
    padding: 2px 4px;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    color: nb-theme(input-basic-text-color);
    border: 2px solid nb-theme(color-primary-hover);
    // background-color: nb-theme(color-primary-hover);
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
    font-weight: bold;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    color: nb-theme(input-basic-text-color);
    border: 2px solid nb-theme(color-primary-hover);
    // background-color: nb-theme(color-primary-hover);
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
    cursor: default;
    // background-color: nb-theme(input-basic-disabled-background-color);
    background-color: nb-theme(background-basic-color-4);
    opacity: 0.8;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
    padding-left: 22px;
  }

  [dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
    padding-right: 22px;
    padding-left: 0;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
    font-size: 80%;
    font-weight: 400;
    padding-right: 5px;
  }

  [dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
    padding-left: 5px;
    padding-right: 0;
  }

  [dir="rtl"] .ng-dropdown-panel {
    direction: rtl;
    text-align: right;
  }

  // ng-dropdown-panel
}