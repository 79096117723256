@mixin realtime-editible-input() {
  .realtime-editible-input.small:not(.big) {
    font-size: 0.6rem;
  }

  .realtime-editible-input:not(.small):not(.big) {
    font-size: 0.8rem;
  }

  .realtime-editible-input.big:not(.small) {
    font-size: 1rem;
  }

  .realtime-editible-input {
    display: block !important;
    box-shadow: none !important;
    font-weight: 400;
    margin: 0 !important;
    width: 100%;

    appearance: auto !important;
    padding-right: 0.3rem !important;
    padding-left: 0.3rem !important;
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
    line-height: 1.5 !important;
    border: 2px solid nb-theme(border-basic-color-3) !important;
    border-radius: 0.25rem;
    color: nb-theme(input-basic-text-color);
    background-color: nb-theme(input-basic-background-color);
  }

  .realtime-editible-input:focus {
    color: nb-theme(input-basic-text-color) !important;
    background-color: nb-theme(input-basic-background-color) !important;
    border-color: nb-theme(color-primary-300) !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem nb-theme(color-primary-transparent-focus) !important;
  }

  .realtime-editible-input:not(:hover):not(:focus),
  .realtime-editible-input:disabled {
    border-color: transparent !important;
    background-color: transparent !important;
  }
}