@mixin tables() {
    //
    // Basic adjustments to Bootstrap table
    //

    .table {
        width: 100%;
        margin-bottom: 0.5rem;
        transform: translate3d(0, 0, 0);

        // Target th & td
        // We need the child combinator to prevent styles leaking to nested tables which doesn't have a `.table` class.
        // We use the universal selectors here to simplify the selector (else we would need 6 different selectors).
        // Another advantage is that this generates less code and makes the selector less specific making it easier to override.
        // stylelint-disable-next-line selector-max-universal
        > :not(caption)>*>* {
            color: nb-theme(text-basic-color);
            background-color: nb-theme(background-basic-color-1);
        }

        >tbody {
            vertical-align: inherit;
        }

        >thead {
            vertical-align: bottom;
        }
    }

    table.clean-table {
        border: 1px solid nb-theme(border-basic-color-3);
        border-bottom-width: 3px;
        border-collapse: collapse;
        color: nb-theme(text-basic-color);

        // width: 100%;
        // display: block;
        // overflow-x: scroll;
        tr {
            display: table-row;
            vertical-align: inherit;
            border-color: inherit;
        }

        tr th {
            background-color: nb-theme(background-basic-color-4);
            border: 1px solid nb-theme(border-basic-color-3);
            color: nb-theme(text-basic-color);
            font-size: 1em !important;
            line-height: 1;
            font-weight: bold;
            padding: 4px;
        }

        tbody {
            color: nb-theme(text-basic-color);
            display: table-row-group;
            vertical-align: middle;
            border-color: inherit;
        }


        tbody td {
            border: 1px solid nb-theme(border-basic-color-3);
            border-top-width: 0;
            font-size: 0.8rem !important;
            padding: 5px;
            text-align: left;
            vertical-align: top;

            p {
                margin: unset !important;
            }
        }
    }
}