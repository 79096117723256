@mixin ic-buttons() {

  button[icButton] {
    appearance: none;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border-radius: 0.25rem;

    cursor: nb-theme(button-cursor);
    font-family: nb-theme(button-text-font-family);
    font-weight: nb-theme(button-text-font-weight);

    &:focus {
      position: relative;
      outline: none;
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }

    &.full-width {
      width: 100%;
    }

    &[disabled],
    &.btn-disabled {
      cursor: nb-theme(button-disabled-cursor);
    }

    @each $size in nb-get-sizes() {
      &.size-#{$size} {
        font-size: nb-theme(button-#{$size}-text-font-size);
        line-height: nb-theme(button-#{$size}-text-line-height);

        nb-icon {
          font-size: nb-theme(button-#{$size}-text-font-size);
          height: nb-theme(button-#{$size}-icon-size);
          width: nb-theme(button-#{$size}-icon-size);
          margin-top: nb-theme(button-#{$size}-icon-vertical-margin);
          margin-bottom: nb-theme(button-#{$size}-icon-vertical-margin);
        }

        $icon-offset: nb-theme(button-#{$size}-icon-offset);

        &.icon-start:not(.icon-end) nb-icon {
          @include nb-ltr(margin-right, $icon-offset);
          @include nb-rtl(margin-left, $icon-offset);
        }

        &.icon-end:not(.icon-start) nb-icon {
          @include nb-ltr(margin-left, $icon-offset);
          @include nb-rtl(margin-right, $icon-offset);
        }

        @each $appearance in ('filled', 'outline', 'ghost', 'hero') {
          &.icon-start.icon-end.appearance-#{$appearance} {
            padding: nb-theme(icon-button-#{$appearance}-#{$size}-padding);
          }
        }
      }
    }

    nb-icon {
      vertical-align: top;
    }

    .a-style {
      background-color: transparent !important;
      text-decoration: underline !important;
      font-weight: 600 !important;
    }

    &.appearance-outline {
      border-style: nb-theme(button-outline-border-style);
      border-width: nb-theme(button-outline-border-width);
      // text-transform: nb-theme(button-outline-text-transform);

      &:focus {
        $inset-shadow: nb-theme(button-outline-focus-inset-shadow-length);
        @include nb-outline(nb-theme(button-outline-width), nb-theme(button-outline-color), $inset-shadow);
      }

      @each $size in nb-get-sizes() {
        &.size-#{$size} {
          padding: nb-theme(button-outline-#{$size}-padding);
        }
      }

      @each $status in nb-get-statuses() {
        &.status-#{$status} {
          background-color: nb-theme(button-outline-#{$status}-background-color);
          border-color: nb-theme(button-outline-#{$status}-border-color);
          color: nb-theme(button-outline-#{$status}-text-color);

          &:focus {
            background-color: nb-theme(button-outline-#{$status}-focus-background-color);
            border-color: nb-theme(button-outline-#{$status}-focus-border-color);
            color: nb-theme(button-outline-#{$status}-focus-text-color);
          }

          &:hover {
            background-color: nb-theme(button-outline-#{$status}-hover-background-color);
            border-color: nb-theme(button-outline-#{$status}-hover-border-color);
            color: nb-theme(button-outline-#{$status}-hover-text-color);
          }

          &:active {
            background-color: nb-theme(button-outline-#{$status}-active-background-color);
            border-color: nb-theme(button-outline-#{$status}-active-border-color);
            color: nb-theme(button-outline-#{$status}-active-text-color);
          }

          &[disabled],
          &.btn-disabled {
            background-color: nb-theme(button-outline-#{$status}-disabled-background-color);
            border-color: nb-theme(button-outline-#{$status}-disabled-border-color);
            color: nb-theme(button-outline-#{$status}-disabled-text-color);
          }
        }
      }
    }

    &.appearance-ghost {
      background-color: nb-theme(button-ghost-background-color);
      border-color: nb-theme(button-ghost-border-color);
      border-style: nb-theme(button-ghost-border-style);
      border-width: nb-theme(button-ghost-border-width);
      // text-transform: nb-theme(button-ghost-text-transform);

      &:focus {
        $inset-shadow: nb-theme(button-ghost-focus-inset-shadow-length);
        @include nb-outline(nb-theme(button-outline-width), nb-theme(button-outline-color), $inset-shadow);
      }

      @each $size in nb-get-sizes() {
        &.size-#{$size} {
          padding: nb-theme(button-ghost-#{$size}-padding);
        }
      }

      @each $status in nb-get-statuses() {
        &.status-#{$status} {
          color: nb-theme(button-ghost-#{$status}-text-color);

          &:focus {
            background-color: nb-theme(button-ghost-#{$status}-focus-background-color);
            border-color: nb-theme(button-ghost-#{$status}-focus-border-color);
            color: nb-theme(button-ghost-#{$status}-focus-text-color);
          }

          &:hover {
            background-color: nb-theme(button-ghost-#{$status}-hover-background-color);
            border-color: nb-theme(button-ghost-#{$status}-hover-border-color);
            color: nb-theme(button-ghost-#{$status}-hover-text-color);
          }

          &:active {
            background-color: nb-theme(button-ghost-#{$status}-active-background-color);
            border-color: nb-theme(button-ghost-#{$status}-active-border-color);
            color: nb-theme(button-ghost-#{$status}-active-text-color);
          }

          &[disabled],
          &.btn-disabled {
            background-color: nb-theme(button-ghost-#{$status}-disabled-background-color);
            border-color: nb-theme(button-ghost-#{$status}-disabled-border-color);
            color: nb-theme(button-ghost-#{$status}-disabled-text-color);
          }
        }
      }
    }

    &.appearance-hero {
      text-shadow: nb-theme(button-hero-text-shadow);
      // text-transform: nb-theme(button-hero-text-transform);

      @each $size in nb-get-sizes() {
        &.size-#{$size} {
          padding: nb-theme(button-hero-#{$size}-padding);
        }
      }

      @each $status in nb-get-statuses() {
        &.status-#{$status} {
          $left-color: nb-theme(button-hero-#{$status}-left-background-color);
          $right-color: nb-theme(button-hero-#{$status}-right-background-color);
          $bevel: nb-theme(button-hero-bevel-size) nb-theme(button-hero-#{$status}-bevel-color);
          $glow: nb-theme(button-hero-glow-size) nb-theme(button-hero-#{$status}-glow-color);
          $shadow: nb-theme(button-hero-shadow);
          $hero-box-shadows: $bevel, $glow, $shadow;

          background-image: linear-gradient(to right, $left-color, $right-color);
          border: none;
          box-shadow: $hero-box-shadows;
          color: nb-theme(button-hero-#{$status}-text-color);

          &:focus {
            $left-focus-color: nb-theme(button-hero-#{$status}-focus-left-background-color);
            $right-focus-color: nb-theme(button-hero-#{$status}-focus-right-background-color);
            background-image: linear-gradient(to right, $left-focus-color, $right-focus-color);
            box-shadow: $hero-box-shadows, 0 0 0 nb-theme(button-hero-outline-width) nb-theme(button-hero-outline-color);
          }

          &:hover {
            $left-hover-color: nb-theme(button-hero-#{$status}-hover-left-background-color);
            $right-hover-color: nb-theme(button-hero-#{$status}-hover-right-background-color);
            background-image: linear-gradient(to right, $left-hover-color, $right-hover-color);
          }

          &:active {
            $left-active-color: nb-theme(button-hero-#{$status}-active-left-background-color);
            $right-active-color: nb-theme(button-hero-#{$status}-active-right-background-color);
            background-image: linear-gradient(to right, $left-active-color, $right-active-color);
          }

          &[disabled],
          &.btn-disabled {
            background-color: nb-theme(button-hero-#{$status}-disabled-background-color);
            background-image: none;
            color: nb-theme(button-hero-#{$status}-disabled-text-color);
          }

          &.button-pulse {
            animation: button-hero-#{$status}-pulse 0.75s infinite alternate;
          }

          @include keyframes(button-hero-#{$status}-pulse) {
            0% {
              box-shadow: none;
              opacity: 0.3;
            }

            100% {
              box-shadow: 0 0 1rem 0 $left-color;
              opacity: 0.8;
            }
          }
        }
      }
    }

    &.appearance-filled {
      border-style: nb-theme(button-filled-border-style);
      border-width: nb-theme(button-filled-border-width);
      // text-transform: nb-theme(button-filled-text-transform);

      @each $size in nb-get-sizes() {
        &.size-#{$size} {
          padding: nb-theme(button-filled-#{$size}-padding);
        }
      }

      @each $status in nb-get-statuses() {
        &.status-#{$status} {
          background-color: nb-theme(button-filled-#{$status}-background-color);
          border-color: nb-theme(button-filled-#{$status}-border-color);
          color: nb-theme(button-filled-#{$status}-text-color);

          &:focus {
            background-color: nb-theme(button-filled-#{$status}-focus-background-color);
            border-color: nb-theme(button-filled-#{$status}-focus-border-color);
          }

          &:hover {
            background-color: nb-theme(button-filled-#{$status}-hover-background-color);
            border-color: nb-theme(button-filled-#{$status}-hover-border-color);
          }

          &:active {
            background-color: nb-theme(button-filled-#{$status}-active-background-color);
            border-color: nb-theme(button-filled-#{$status}-active-border-color);
          }

          &[disabled],
          &.btn-disabled {
            background-color: nb-theme(button-filled-#{$status}-disabled-background-color);
            border-color: nb-theme(button-filled-#{$status}-disabled-border-color);
            color: nb-theme(button-filled-#{$status}-disabled-text-color);
          }
        }
      }
    }
  }

  .ic-button-grp {
    display: inline-flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-start;

    button:not(:last-child):not(:first-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    button:first-child:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    button:last-child:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}